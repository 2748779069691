import { useUserStore } from "~/stores";
import { authInteractor } from "~/core";

export default defineNuxtRouteMiddleware(async(to, from) => {
  if (to.path === '/search') {
    /**
     * 判断登录用户的token是否过期
     * 如果token过期，则跳转到登录页面
     */
    const store = useUserStore();
    if (store.accessToken && store.refreshToken) {
      const [user, err] = await authInteractor.getUserInfoApi(
        store.accessToken,
        store.refreshToken
      );
      if (!user) {
        return navigateTo('/account/signin?expire=1');
      }
    }
  }
});
